
import { Component, Vue, Watch } from "vue-property-decorator";
import { getproduct, updateproduct, delproduct, addproduct, getCategory, getPictureByProductid, addproductPiture, delProductPicture} from "../api/ApiPath";
import FileUpload from "../components/FileUpload.vue";


@Component({ components: { FileUpload } })
export default class AdminProduct extends Vue {
    addTableVisible = false;
    editTableVisible = false;
    changeTableVisible = false;
    filePicture = [];
    filePictureDetail = [];
    productPicture = {
        product_id: 0,
        name: "",
        url: "",
        isMain: 0,
    };
    formLabelWidth = "120px";
    category = [];
    productform = {
        product_id: 0,
        product_name: "",
        category_id: 0,
        product_title: "",
        product_intro: "",
        product_picture: [],
        product_price: 0.0,
        product_num: 0,
        product_sales: 0,
        product_details: "",
    };

    pictureform = {
        id: 0,
        product_id: 0,
        name: "",
        url: "",
        isMainPicture: 0,
    }


    tableData = [];
    scope: any;
    init() {
        getproduct(
            (res: any) => {
                const { product, status, msg } = res;
                if (status === 200) {
                    this.tableData = product;
                }
            },
            (err: any) => {
                this.$message.error(err);
            }
        );
    }
    edit(row: any) {
        this.productform = row;
        this.changeTableVisible = true;
        getPictureByProductid({product_id:this.productform.product_id, isMain:0}, (res: any) => {
			setTimeout(() => {
				this.filePicture = [...res.data] || [];},100)
            },(err: any) => console.log(err))
            getPictureByProductid({product_id:this.productform.product_id, isMain:1}, (res: any) => {
			setTimeout(() => {
				this.filePictureDetail = [...res.data] || [];},100)
            },(err: any) => console.log(err))   
    }
    cancel() {
        this.changeTableVisible = false;
        this.resetForm();
    }
    resetForm() {
      // 直接使用默认值重置 productform
        // this.productform = Object.assign({}, this.productform);
        const defaultForm = {
            product_id: 0,
            product_name: "",
            category_id: 0,
            product_title: "",
            product_intro: "",
            product_picture: [], // 确保这是新的空数组
            product_price: 0.0,
            product_num: 0,
            product_sales: 0,
            product_details: "", // 确保这是新的空数组
            };
            this.productform = { ...defaultForm };
    }

    // 增加和修改
    addandupdate() {
        this.changeTableVisible = false;
        if (this.productform.product_id === 0) {
            this.add();
        } else {
            this.update();
        }
        this.resetForm();
    }
    add() {
        // this.addTableVisible = false;
        addproduct(
            this.productform,
            (res: any) => {
                this.init();
                let { status, msg } = res;
                this.$message.success(msg);
            },
            (err: any) => {
                this.$message.error(err);
            }
        );
    }
    update() {
        // this.editTableVisible = false;
        updateproduct(
            this.productform,
            (res: any) => {
                this.init();
                let { status, msg } = res;
                this.$message.success(msg);
            },
            (err: any) => {
                this.$message.error(err);
            }
        );
    }
    del(row: any) {
        this.$confirm("确定删除该产品？", "确定", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            delproduct(
                row,
                (res: any) => {
                    this.init();
                    let { status, msg } = res;
                    this.$message.success(msg);
                },
                (err: any) => {
                    this.$message.error(err);
                }
            );
            // this.$message.warning('目前暂不能删除，请和管理员联系')
        });
    }
    created() {
        this.init();
    }

    formatCategory(row: any) {
        if (this.category) {
            let item = this.category.find((o) => {
                return o.category_id == row.category_id;
            });
            return item && item.category_name;
        }
    }

    // 父组件接收子组件传递过来的 fileList 更新数据库
    updateFileList(updatedFile: any) {
        this.addproductPiture(updatedFile, 0);
    }
    updateFileList1(updatedFile: any) {
        this.addproductPiture(updatedFile, 1);
    }
    addproductPiture(updatedFile:any, isMain: number) {
        this.productPicture.product_id = this.productform.product_id;
        this.productPicture.name = updatedFile.response.data.name;
        this.productPicture.url = updatedFile.response.data.url;
        this.productPicture.isMain = isMain;
        addproductPiture(this.productPicture, (res: any) => {
            let { status, msg } = res;
            this.$message.success(msg);
         },(err: any) => {
                this.$message.error(err);
         });   
    }
    removeFile(file: any) {
        delProductPicture({name: file.name}, (res: any) => {
            let { status, msg } = res;
            this.$message.success(msg);
        },(err: any) => {
                this.$message.error(err);
        });
    }

    // 计算属性，确保返回数组
    // get normalizedProductPicture(): Array<{ name: string; url: string }> {
    //     console.log("normalizedProductPicture getter call url"+this.productform.product_picture);
    //     if (Array.isArray(this.productform.product_picture)) {
    //         return this.productform.product_picture;
    //     }
    //     // 如果是字符串类型，尝试解析 JSON
    //     if (typeof this.productform.product_picture === "string") {
    //         try {
    //             const parsed = JSON.parse(this.productform.product_picture);
    //             if (Array.isArray(parsed)) {
    //                 console.log("normalizedProductPicture getter call parsed"+parsed);
    //                 return parsed;
    //             }else {console.log("normalizedProductPicture not call");return [];}
    //         } catch (error) {
    //             console.log("Failed to parse product_picture:", error);
    //         }
    //     }
    //     console.log("product_picture is not an array, returning empty array");
    //     return [];
    // }
    mounted() {
        getCategory(
            (res: any) => {
                this.category = res.category;
            },
            (err: any) => {
                this.$message.error(err);
            }
        );
    }
}
